

.CardsBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow: auto; 
    flex-direction: row;
}

.CardsBoxTitle{
    text-align: left;
    width: 98%;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;
}

.Cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: row;
}

.Card{
    margin: 1%;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 2px 5px rgb(171, 171, 171), -3px -2px 5px rgba(190, 190, 190, 0.678);
    cursor: pointer;
}

.CardCover{
    background-image: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 2%;
}

.GymPickDesign{
    width: 25vw;
    height: 30vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: end;
    flex-direction: row;
    background-image: url("../public/GymAssets/GymBox1.jpg");
}

.SubscriptionDesign{
    width: 25vw;
    height: 20vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
}


.SubscriberDesign{
    width: 24vw;
    height: 30vh;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    font-size: 9pt;
}

.SubscriptionDesign .Icon{
    width: 5vw;
    height: 5vw;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    font-size: 20pt;
    background-color: rgba(236, 236, 236, 0.604);
    box-shadow: 1px 1px 5px rgb(255, 255, 255), -1px -1px 5px rgb(255, 255, 255);
    margin: 1%;
}

.SubscriptionDesign .Icon .IconSmallText{
    font-size: 8pt;
}

.SubscriptionDesign .Text{
    border-radius: 10px;    
    margin: 1%;
}

.GymPickDesign .Text{
    width: 100%;
    background-color: rgba(240, 248, 255, 0.736);
    text-align: center;
    font-weight: bold;
    padding-top: 1%;
    padding-bottom: 1%;
}

.Card .Text span{
    font-size: 8pt;
    font-weight: 300;
}

.HeadText{
    font-size: medium;
    font-weight: bold;
}

.SubText{
    font-size: small;
}

.DashboardCardDesign{
    width: 20vw;
    height: 10vh;
    background-color: rgb(235, 235, 235);
    border: solid 2px rgb(218, 218, 218);
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.AddNewAnythingDesign{
    background-color: whitesmoke;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    text-align: center;
}

.AddNewAnythingDesign .PlusButton{
    font-size: 40pt;
}


@media only screen and (max-width: 480px) {

    .CardsBoxTitle{
        font-size: 10pt;
    }

    .Card{
        width: 99% !important;
        height: 25vh !important;
        margin-top: 2%;
    }

    .SubscriptionDesign{
        height: 15vh !important;
    }

    .SubscriptionDesign .Icon{
        width: 20vw !important;
        height: 20vw !important;
        background-color: whitesmoke;
        flex-direction:column;
    }

    .CardCover{
        justify-content: space-around;
    }

    .SearchBar{
        width: 94%;
        height: 5vh;
        padding-left: 6%;
        background-size: 5%;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        align-content: center;
        flex-direction: row;
    }

    .ClientCard{
        width: 100%;
        height: 26vh;
    }
}