@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.CreateGymForm{
    
    width: 40vw;
    height: 80vh;
    border: solid 1px grey;
    border-radius: 10px;
    padding: 2%;
}


table{
    width: 100%;
}

tr{
    width: 100%;
}