

.Loading{
    width: 80%;
    height: 5px;
    border-radius: 2px;
}

.ProgressBar{
    
}