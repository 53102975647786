
body{
    background-color: rgb(255, 255, 255);
}

input[type=button]{
    width: 50%;
    height: 36px;
    background-color: rgb(21, 21, 21) !important;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-top: 5%;
    border: none;
    font-size: 10pt;
}

input[type=text]{
    width: 90%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}
input[type=number]{
    width: 90%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}


input[type=password]{
    width: 90%;
    height: 30px;
    border-radius: 2px;
    outline: none;
    border: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}

input[type=date]{
    width: 90%;
    height: 30px;
    border-radius: 5px;
    border: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}

input[type=phone]{
    width: 90%;
    height: 30px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}

input[type=email]{
    width: 90%;
    height: 30px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
    background-color: #edf9ff;
}


select{
    width: 94%;
    height: 30px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 10pt;
    padding: 0.4%;
    padding-left:2%;
    padding-right: 2%;
}

.RupeeSymbol{
    background-image: url("https://cdn-icons-png.flaticon.com/512/25/25473.png");
    background-position: left;
    background-position-x: 5%;
    background-size: 5%;
    background-repeat: no-repeat;
    padding-left: 20%;
    text-align: right;
}

.PercentSymbol{
    background-image: url("https://cdn-icons-png.flaticon.com/512/253/253283.png");
    background-position: right;
    background-position-x: 95%;
    background-size: 5%;
    background-repeat: no-repeat;
    padding-right: 20%;
}

.Background{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;  
    background-color: #e3e3e3; 
    width: 100vw;
    height: 100vh;
}

.Header{
    width: 100%;
    height: 10%;

    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;  
}

.MainBox{
    width: 100%;
    height: 90%;

    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;  
}

.MainBox .CBLeft{
    width: 10%;
    height: 100%;
}

.MainBox .CBRight{
    height: 97.5%;
    width: 85%;
    overflow-y: auto;
    padding-left:1.5%;
    padding-right: 1.5%;
    padding-top: 1.5%;
    margin-left:1%;
    margin-right: 1%;
    margin-top: 1%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
}

a{
    color: black;
}

.LeftSideArea{
    width: 30%;
}

.AccountControls{
    width: 30%;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row; 
}

.AccountControls .Logout{
    margin-right: 5%;
    font-size: 10pt;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row; 
    cursor: pointer;
}

.LogoPart{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: row;  
    width: 40%;
}

.LogoText{
    font-weight: bold;

    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    overflow: hidden;
    flex-direction: column;  
}

.LogoText .TagLine{
    font-size: 8pt;
    font-weight: 400;
}

.Logo{
    background-image: url("../public/Logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 50px;
    height: 50px;

    margin: 1%;
    margin-left: 3%;

    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(255, 255, 255), -2px -2px 2px grey;
}

.ContentBox{
    width: 90%;
    height: 30%;
    margin: 1%;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 5px;
    background-color: whitesmoke;
}


.ContentHeader{
    font-size: 11pt;
    width: 99%;
    height: 12%;
    padding: 0.5%;
    background-color: #00aaff;
    color: rgb(255, 255, 253);
}

.ContentArea{
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: row;
}

.ClickAbleBox{
    width: 10vw;
    height: 10vw;
    border-radius: 10px;
    background-color: rgb(234, 234, 234);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: column;
    cursor: pointer;
    margin: 1%;
    box-shadow: 1px 1px 5px rgb(208, 208, 208);
}

.ClickAbleBox .Icon{
    width: 50%;
    height: 50%;
    margin: 5%;
    font-size: 25pt;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: column;
}

.Icon .fitness{
    width: 100%;
    height: 100%;
    background-image: url("../public/fitness.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Icon .client{
    width: 100%;
    height: 100%;
    background-image: url("../public/client.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.ClickAbleBox .Text{
    font-size: 10pt;
    text-align: center;
}

.ClientCard{
    width: 24vw;
    height: 28vh;
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 1%;
    margin: 1%;
    border: rgb(222, 222, 222) 1px solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: space-between;
    overflow: hidden;
    flex-direction: column;
    text-align: left;
    cursor: pointer;
}


.ClientCard .PersonalDetails{
    width: 98%;
    text-align: left;
    font-size: 10pt;
}


.ClientCard .ClientName{
    font-size: 12pt;
    font-weight: bold;
    text-align: left;
}
.ClientCard .ClientPhone{
    font-size: 10pt;
    text-align: right;
}

.ClientCard .SubscriptionDetails{
    width: 98%;
    text-align: left;
    font-size: 8pt;
}

.SearchBar{
    width: 50%;
    padding-left: 2%;
    background-color: rgb(237, 237, 237);
    background-image: url("../public/search.png");
    background-position: left;
    background-size: 3%;
    background-position-x: 0.8%;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.SearchBar input[type=text]
{
    background-color: rgb(237, 237, 237);
}


.NavMenu{
    width: 100%;
    height: 15vh;
    padding: 1%;
    margin-bottom: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;
    text-align: left;
}

.NMLogo{
    background-image: url("../public/Logo.png");
    background-position: center;
    background-size: contain;
    background-position-y: bottom;
    background-repeat: no-repeat;
    width: 150px;
    height: 12vh;
    margin-left: 3%;
}

.NMBar{
    width: 70%;
    height: 95%;
    margin-right: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    overflow: hidden;
    flex-direction: row;
    text-align: left;
}

.NMBar .NMBarClick{
    margin: 2%;
    text-decoration: none;
    font-size: 10pt;
}

.NMBarBottom{
    width: 88%;
    height: 20%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: right;
    font-size: 8pt;
}

.Info{
    width: 80%;
    text-align: left;
}


.CurrentSubscription{
    width: 80%;
    margin-top: 2%;
}

.PrintBtn{
    position: absolute;
    right: 15%;
    top: 25%;
}

.LoginBackground {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: column;
    
    width: 100%;
    height: 100%;
    
    background-image: url("../public/background.jpg");
    background-position: center;
    background-size: cover;
}

.ManageGymBackground{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background-color: rgb(255, 255, 255);
    /* background-image: url("../public/background.jpg"); */
    background-position: center;
    background-size: cover;
}

.MODAL{
    top: 0; bottom: 0;
    left: 0; right: 0;
    background-color: rgba(80, 80, 80, 0.623);
    position: absolute;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    display:none;
}

.CBLeftButtons{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.CBLeftButton{
    margin: 2%;
    margin-left: 6%;
    padding: 1%;
    padding-left: 6%;
    text-align: left;
    font-size: 9pt;
    font-weight: 400;
    height: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    border-radius: 5px;
    /* box-shadow: 2px 2px 3px rgb(188, 188, 188); */
    color: black;
    cursor: pointer;
}

.CBLeftButtonDivider{
    width: 90%;
    height: 2px;
    background-color: rgb(99, 99, 99);
    margin: 5%;
}


.NavStripe{
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    position: absolute;
    padding: 1%;
}

.NavStripe-item{
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    font-size: 8pt;
}

.NavStripe-item a{
    text-decoration: none;
    color: black;
}

.MessageBox{
    color: red;
    font-size: 12pt;
    margin: 2%;
}



.CBLeft .MobileLogout{
    display: none;
}

.MobileMenu{
    display: none;
}

.OpenSpace{
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
}

@media only screen and (max-width: 480px) {

    .Background{
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }

    .Header{
        width: 100%;
        height: 15vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: row;
    }

    .LeftSideArea{
        width: 20%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: row;
        overflow: hidden;
    }

    .AccountControls{
        width: 20%;
        height: 100%;
        overflow: hidden;
    }

    .LogoPart{
        width: 50%;
        height: 60%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: row;
        overflow: hidden;
        margin-top: 8%;
    }

    .LogoPart .Logo{
        width: 15vw;
        height: 15vw;
    }

    .LogoPart .LogoText{
        font-size: 8pt;
        margin-left: 2%;
    }

    .MainBox .CBRight {
        width: 99%;
        height: 100vh;
        overflow: auto;
        justify-content: flex-start;
    }

    
    .CBLeft{
        position: fixed !important;
        width: 100vw !important;
        height: 87vh !important;
        display: block;
        background-color: rgb(242, 242, 242);
        position: absolute;
        bottom: -100vh; /* Initial position */
        transition: bottom 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .Form{
        width: 90% !important;
        height: 90% !important;
    }

    .LoginForm{
        width: 90% !important;
        height: 90% !important;
    }

    .Form .FieldRow{
        width: 90% !important;
    }

    .Form .FieldRow input{
        height: 40px;
    }


    .show {
        bottom: 0;
    }

    .hide {
        bottom: -100vh;
    }

    .CBLeft .MobileMenu{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-direction: row;
        overflow: hidden;
    }

    .MobileMenu .Title{
        margin-left: 12%;
        margin-top: 8%;
        font-weight: bold;
    }

    .MobileMenu .CloseBtn{
        color: red;
        margin-right: 8%;
        margin-top: 6%;
        font-weight: bolder;
        font-size: 15pt;
    }

    .CBLeft .MobileLogout{
        display: block;
    }

    
    .AccountControls .Logout{
        display: none;
    }

            
    .menu-btn__burger {
        margin-top: 8%;
        width: 35px;
        height: 3px;
        background: #333;
        border-radius: 5px;
        transition: all 0.3s ease;
        position: absolute;
    }

    .menu-btn__burger::before,
    .menu-btn__burger::after {
        content: '';
        width: 35px;
        height: 3px;
        background: #333;
        border-radius: 5px;
        transition: all 0.3s ease;
        position: absolute;
    }

    .menu-btn__burger::before {
        top: -10px;
    }

    .menu-btn__burger::after {
        top: 10px;
    }

    .LeftSideArea.open .menu-btn__burger {
        transform: rotate(45deg);
    }

    .LeftSideArea.open .menu-btn__burger::before {
        top: 0;
        transform: rotate(90deg);
    }

    .LeftSideArea.open .menu-btn__burger::after {
        top: 0;
        transform: rotate(90deg);
    }
}