/* LoadingStrip.css */

.loading-container {
  width: 100%;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.loading-strip {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,123,255,0.5) 50%, rgba(255,255,255,0) 100%);
  position: absolute;
  animation: slide 2s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
